import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import Reducer from "../utilities/Reducer";
import { useNavigate, useParams } from "react-router-dom";

import Logo from "../images/logo.jpg";
import Footer from "./components/Footer";

import "../css/style.css";

const Step10_Shipping = () => {
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [order, setOrder] = useState({});
    const [countries, setCountries] = useState([]);
    const [token] = useState(sessionStorage.getItem("token"));

    const [user, setUser] = useState({
        salutation: "",
        firstname: "",
        lastname: "",
        company: "",
        street: "",
        place: "",
        city: "",
        country: "176",
        email: "",
        confirmemail: ""
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        requestResources();

        if (orderId !== "") {

            async function loadOrder() {
                let order = await requestCompletedOrder();

                setOrder(order);
            }

            async function loadCountries() {
                let countries = await requestCountries();

                if (countries == null) navigate(`/${language}/home`);;

                //first add switzerland, germany and austria
                const indexCH = countries.findIndex((c) => c.id === 176);
                const ch = countries[indexCH];
                countries.splice(indexCH, 1);

                var indexDE = countries.findIndex((c) => c.id === 229);
                const de = countries[indexDE];
                countries.splice(indexDE, 1);

                var indexAU = countries.findIndex((c) => c.id === 228);
                const au = countries[indexAU];
                countries.splice(indexAU, 1);

                countries.unshift({ id: "", name: "---------" });
                countries.unshift(au);
                countries.unshift(de);
                countries.unshift(ch);

                setCountries(countries);
            }

            loadOrder();
            loadCountries();
        } else {
            navigate(`/${language}/home`);
        }

    }, [language]); //everytime language is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestCountries = async () => {
        var response = await axios.get(`${baseUrl}/Countries?languageCode=${language.slice(0, 2)}`);
        return response.data;
    };

    const requestCompletedOrder = async () => {
        var response = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
        return response.data;
    };

    const onSubmit = () => {
        addAddressToBasket();
    };

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    // validating input fields
    let validation =
        /^([a-z A-Z������]{1,70})$/.test(user.salutation) &&
        /^.{1,70}$/.test(user.firstname) &&
        /^.{1,70}$/.test(user.lastname) &&
        /^.{1,70}$/.test(user.street) &&
        /^.{1,70}$/.test(user.place) &&
        /^.{1,70}$/.test(user.city) &&
        /^([a-z A-Z������ 0-9 - ]{1,70})$/.test(user.country) &&
        /^\S+@\S+\.\S+$/.test(user.email) &&
        /^\S+@\S+\.\S+$/.test(user.confirmemail) &&
        user.email === user.confirmemail;

    const addAddressToBasket = () => {
        let addressBody = {
            salutation: user.salutation,
            firstname: user.firstname,
            name: user.lastname,
            company: user.company,
            street: user.street,
            postCode: user.place,
            city: user.city,
            countryId: user.country,
            email: user.email,
            languageId: languageId
        };
        axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then((res) => {
                /* redirect to checkout page if its the last interation */
                if (order?.totalPrice === 0) {
                    changePaymentTypeToFree();
                } else {
                    navigate(`/${language}/checkout`);
                }
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    };

    console.log(order);

    const changePaymentTypeToFree = async () => {
        await axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(() => {
                confirmShopbasketByOrderId(orderId);
                navigate(`/${language}/confirmation/${orderId}`);
            });
    };

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <div>
            {/* loop the ticket types */}
            <>
                {resources.translation && (
                    <div className="container wrapper text-center">
                        <img className="img-fluid logo" src={Logo} alt="logo" />
                    </div>
                )}
                {resources.translation && (
                    <div className="container pt-0 mt-5 wrapper">
                        <div className="row title-box">
                            <h4>{resources.translation.ShippingAddress}</h4>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">
                                <label>{resources.translation.ShippingAnrede}</label>
                            </div>
                            <div className="col-md-9">
                                <select
                                    className="form-control border-dark"
                                    aria-label={resources.translation.ShippingAnrede}
                                    name="salutation"
                                    value={user.salutation}
                                    onChange={(e) => onInputChange(e)}>
                                    <option>{resources.translation.ShippingPleaseChoose}</option>
                                    <option value={resources.translation._Mr}>{resources.translation._Mr}</option>
                                    <option value={resources.translation._Mrs}>{resources.translation._Mrs}</option>
                                </select>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">
                                <label>{resources.translation.ShippingFirstName}</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="firstname"
                                    className="form-control border-dark"
                                    value={user.firstname}
                                    onChange={(e) => onInputChange(e)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">
                                <label>{resources.translation.ShippingLastName}</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="lastname"
                                    className="form-control border-dark"
                                    value={user.lastname}
                                    onChange={(e) => onInputChange(e)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">
                                <label>{resources.translation.ShippingCompany}</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="company"
                                    className="form-control border-dark"
                                    value={user.company}
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">
                                <label>{resources.translation.ShippingStreet}</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="street"
                                    className="form-control border-dark"
                                    value={user.street}
                                    onChange={(e) => onInputChange(e)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">
                                <label>{resources.translation.ShippingPlaceCity}</label>
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    name="place"
                                    className="form-control border-dark"
                                    value={user.place}
                                    onChange={(e) => onInputChange(e)}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    name="city"
                                    className="form-control border-dark"
                                    value={user.city}
                                    onChange={(e) => onInputChange(e)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">
                                <label>{resources.translation.ShippingCountry}</label>
                            </div>
                            <div className="col-md-9">
                                <select
                                    className="form-control border-dark"
                                    aria-label="Land"
                                    name="country"
                                    value={user.country}
                                    onChange={(e) => onInputChange(e)}>
                                    {countries.map((c, index) => (
                                        <option value={c.id} key={index}>
                                            {c.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">
                                <label>{resources.translation.ShippingEmail}</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control border-dark"
                                    value={user.email}
                                    onChange={(e) => onInputChange(e)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">
                                <label>{resources.translation.ShippingEmailConfirmation}</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="confirmemail"
                                    className="form-control border-dark"
                                    value={user.confirmemail}
                                    onChange={(e) => onInputChange(e)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-12">
                                <label>{resources.translation._Mandatory}</label>
                            </div>
                        </div>

                        <div className="row pt-3 mt-4">
                            <div className="col-md-3 text-end">
                                <button className="btn form-control btn-custom"
                                    onClick={() => navigate(`/${language}/home`)}>
                                    {resources.translation._Back}
                                </button>
                            </div>
                            <div className="offset-md-6 col-md-3 text-end">
                                <button className="btn form-control btn-custom" onClick={onSubmit} disabled={!validation}>
                                    {resources.translation._Next}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="container footer-wrapper">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <Footer language={language} />
                        </div>
                    </div>
                </div>
            </>
        </div >
    );
};

export default Step10_Shipping;