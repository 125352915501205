import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Logo from "../images/logo.jpg";
import Seatmap from "../images/seatmap.png";
import ImageGaladeiGastelli from "../images/galadeigastelli.jpg";
import Footer from "./components/Footer";

import Loading from "./components/Loading";
import "../css/style.css";

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [eventId, setEventId] = useState("");
    const [orderId, setOrderId] = useState("");
    const [orderTotal, setOrderTotal] = useState(0);
    const [eventInfo, setEventInfo] = useState({});
    const [ticketTypes, setTicketTypes] = useState([]);
    const [selectedTicketTypes] = useState([]);
    const [isNotValid, setIsNotValid] = useState(true);
    const [token, setToken] = useState(sessionStorage.getItem("token"));

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const loadToken = () => {
        axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            setToken(res.data);
            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {
            setEventId(res.data.eventId);
            startOrder(res.data.posId);
        });
    };


    useEffect(() => {

        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        if (orderId !== "") {

            async function loadEvent() {
                var event = await requestEvent();

                if (event == null) return;

                sessionStorage.setItem("FUWEvent", JSON.stringify(event));
                var eventInfos = await requestEventInfo(event.id);

                // instead of setting it as an object, we were adding the object to an array!
                setEventInfo(eventInfos.eventInfos.find((infos) => infos.languageId === language));

                var ticketTypesOfEvent = await requestTicketTypes();
                ticketTypesOfEvent = ticketTypesOfEvent.ticketTypes.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0)); //sort

                ticketTypesOfEvent = ticketTypesOfEvent.filter(tt => new Date(tt.preSaleEnd) > new Date());

                var availabilities = ticketTypesOfEvent.map(async (tt) => {
                    let availability = await requestTicketTypeNumberOfAvailableTickets(tt.id, ""); //get availability
                    tt.availability = availability;
                });
                await Promise.all(availabilities);


                var infos = ticketTypesOfEvent.map(async (tt) => {
                    var ticketTypeInfos = await requestTicketTypeInfo(tt.id);
                    tt.info = ticketTypeInfos.ticketTypeInfos.find(info => info.languageId == languageId);;
                });
                await Promise.all(infos);

                setTicketTypes(ticketTypesOfEvent);
                setLoading(false);
            }

            loadEvent();
        }

    }, [orderId]); //everytime orderId is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const startOrder = (posId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId,
            abbreviation: "",
            languageId: languageId
        };

        axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("OrderId", res.data.id);

                setOrderId(res.data.id);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestEvent = async () => {
        var response = await axios.get(`${baseUrl}/Event/${eventId}`);
        return response.data;
    };

    const requestEventInfo = async () => {
        var response = await axios.get(`${baseUrl}/Event/${eventId}/Infos`);
        return response.data;
    };

    const requestTicketTypes = async () => {
        var response = await axios.get(`${baseUrl}/Event/${eventId}/TicketTypes`);
        return response.data;
    };

    const requestTicketTypeNumberOfAvailableTickets = async (ticketTypeId, code) => {

        if (ticketTypeId == null || code == null) return;

        let url = `${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}`;

        var response = await axios.get(url);
        return response.data;
    };

    const requestTicketTypeInfo = async (ticketTypeId) => {
        var response = await axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`);
        return response.data;
    };

    const onTicketTypeChange = (e, ticketTypeId) => {
        if (selectedTicketTypes.length < 1) {
            selectedTicketTypes[0] = {
                ticketTypeId: ticketTypeId,
                quantity: e.target.value
            };
        } else {
            selectedTicketTypes.map((t) => {
                const testSelectedTicketTypes = selectedTicketTypes.filter((f) => f.ticketTypeId === ticketTypeId);

                if (testSelectedTicketTypes.length === 1) {
                    selectedTicketTypes.find((f) => f.ticketTypeId === ticketTypeId).quantity = e.target.value;
                } else {
                    selectedTicketTypes[selectedTicketTypes.length] = {
                        ticketTypeId: ticketTypeId,
                        quantity: e.target.value
                    };
                }
            });
        }

        let totalQuantity = 0;
        let totalPrice = 0;

        selectedTicketTypes.forEach((stt) => {
            totalQuantity = totalQuantity + stt.quantity * 1;

            totalPrice = totalPrice + ticketTypes.find((tt) => tt.id === stt.ticketTypeId).price *
                stt.quantity;
        });

        setOrderTotal(totalPrice);

        if (totalQuantity > 0) {
            setIsNotValid(false);
        } else {
            setIsNotValid(true);
        }
    };


    const mapTicketsTypes = () => {
        return (
            ticketTypes?.length > 0 &&
            ticketTypes.map(
                (tt, index) => {

                    if (tt.info && tt.availability && tt.availability.status === 0) {

                        return (
                            <div key={tt.id}>
                                <div className="row pt-3 pb-4 boxBorder">
                                    <div className="col-md-8">
                                        <label>
                                            {tt.info.name}
                                        </label>
                                    </div>
                                    <div className="col-md-2 text-md-end">
                                        <label>{tt.currency} {tt.price.toFixed(2)}</label>
                                    </div>
                                    <div className="col-md-2 text-md-end">
                                        <select className="form-select ticket-qtd-ddl" onChange={(e) => onTicketTypeChange(e, tt.id)}>
                                            {Array.from(Array(tt.availability.availableTickets + 1),
                                                (e, i) => {
                                                    return <option key={i} value={i}>{i}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>
                            </div>);
                    }

                }
            )
        );
    };

    const addTicketToBasket = () => {

        let addTicketTypes = [];

        addTicketTypes = selectedTicketTypes.filter((stt) => stt.quantity !== "0");

        axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                navigate(`/${language}/shipping`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };


    const onSubmit = () => {
        addTicketToBasket();
    };

    return (
        <div>
            {loading ? (
                <>
                    <div className="d-none d-lg-block">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="50%"
                            left="50%"
                        />
                    </div>
                    <div className="d-lg-none">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="45%"
                            left="45%"
                        />
                    </div>
                </>
            ) : (
                <>
                    {resources.translation && (
                        <div className="container wrapper text-center">
                            <img className="img-fluid logo" src={Logo} alt="logo" />

                            <div className="row mt-3 mb-5">
                                <div className="col-md-12 mb-2 text-center description">
                                    <span dangerouslySetInnerHTML={{ __html: resources.translation.HomeDescription }} className="description"></span>
                                </div>
                            </div>
                        </div>
                    )}
                    {resources.translation && (
                        <div className="container pt-0 wrapper">
                            <div className="row title-box">
                                <h4>{resources.translation.HomeEventDetails}</h4>
                            </div>
                            <div className="row mt-4 mb-5">
                                <div className="col-md-12 mb-4 text-center">
                                    <span>{resources.translation.HomeEventDescription}</span>
                                </div>

                                <div className="col-md-6 text-center">
                                    <img className="img-fluid" src={ImageGaladeiGastelli} alt="img" />
                                </div>

                                <div className="col-md-6 text-center">
                                    <img className="img-fluid" src={Seatmap} alt="Saalplan" />
                                </div>
                            </div>

                            <div className="row title-box mb-4">
                                <h4>{resources.translation.HomeGuestTickets}</h4>
                            </div>

                            {mapTicketsTypes()}

                            {/*<div className="row">*/}
                            {/*<span>{resources.translation.MainEventTickets}</span>*/}
                            {/*    <a dangerouslySetInnerHTML={{ __html: resources.translation.MainEventTicketsUrl }} href={resources.translation.MainEventTicketsUrl} target="_blank"></a>*/}
                            {/*</div>*/}

                            <div className="row pt-3 border-hr mt-4"></div>

                            <div className="row pt-3 mt-2">
                                <div className="col-md-8">
                                    <span>{resources.translation.Total}</span>
                                </div>
                                <div className="col-md-2 text-end">
                                    CHF {orderTotal.toFixed(2)}
                                </div>
                            </div>

                            <div className="row pt-3 mt-4">
                                <div className="offset-md-9 col-md-3 text-end">
                                    <button className="btn form-control btn-custom" onClick={onSubmit} disabled={isNotValid}>
                                        {resources.translation.HomeAddToBasket}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="container footer-wrapper">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Footer language={language} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Home;