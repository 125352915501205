import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import pdfImage from "../images/pdf-icon.png";
import Logo from "../images/logo.jpg";
import Footer from "./components/Footer";

import Loading from "./components/Loading";

const Step30_Confirmation = () => {
    const [loading, setLoading] = useState(true);
    const [resources, setResources] = useState({});
    const [orderId] = useState(
        sessionStorage.getItem("OrderId")
    );
    const [order, setOrder] = useState({});
    const [organizerId, setOrganizerId] = useState("");

    const [token] = useState(sessionStorage.getItem("token"));

    // fetching resources
    const { language } = useParams();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);
        });

        requestFormSettings()
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            setOrganizerId(res.data.organizerId);
            loadOrder(orderId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);

            const totalPrice = order.totalPrice;

            setOrder(order);
            setLoading(false);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const getPdfReceiptOfOrder = async () => {
        await axios
            .get(`${baseUrl}/Order/${orderId}/Receipt?organizerId=${organizerId}`, {
                responseType: "blob",
            })
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Receipt.pdf";
                link.click();
            })
            .catch((error) => console.error(error.response.data));
    };

    const getPdfTicketsOfOrder = async () => {
        await axios
            .get(
                `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Tickets.pdf";
                link.click();
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <div>
            {loading ? (
                <>
                    <div className="d-none d-lg-block">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="50%"
                            left="50%"
                        />
                    </div>
                    <div className="d-lg-none">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="45%"
                            left="45%"
                        />
                    </div>
                </>
            ) : (
                <>
                    {resources.translation && (
                        <div className="container wrapper text-center">
                            <img className="img-fluid logo" src={Logo} alt="logo" />
                        </div>
                    )}
                    {resources.translation && (
                        <div className="container wrapper">
                            <div className="m-5">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <h1>
                                            {resources.translation.ConfirmationTitle}
                                        </h1>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="row">
                                        <div className="col-md-6 ">
                                            <p>{resources.translation.ConfirmationText1} {order.invoiceNumber && order.invoiceNumber}</p>
                                            <p>{resources.translation.ConfirmationText2} {order.total && order.total.toFixed(2)}</p>
                                            <div dangerouslySetInnerHTML={{ __html: resources.translation.ConfirmationText3 }} className="description"></div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-8 mt-4">
                                            <img src={pdfImage} className="pdf-icon" alt="car picture"></img>
                                            <button
                                                className="custom-button form-control pdf-button btn-custom"
                                                onClick={() => getPdfTicketsOfOrder()}
                                            >
                                                {resources.translation.ConfirmationDownloadTicket}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-8 mt-4">
                                            <img src={pdfImage} className="pdf-icon" alt="car picture"></img>
                                            <button
                                                className="custom-button form-control pdf-button btn-custom"
                                                onClick={() => getPdfReceiptOfOrder()}
                                            >
                                                {resources.translation.ConfirmationDownloadInvoice}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container footer-wrapper">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <Footer language={language} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Step30_Confirmation;
