import axios from "axios";
import React, { useEffect, useState } from "react";

import sponsors from "../../images/sponsors.png";

const Footer = (props) => {
    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [props.language]);

    const requestResources = () => {
        axios.get(`form/resources/${props.language}`).then((res) => {
            setResources(res.data);
        });
    };

    return (
        <div className="mt-5">
            <div className="row">
                {/*<img className="img-fluid" src={sponsors} alt="logo" />*/}
            </div>
            <div className="row">
                <p className="mt-3">
                    {resources.translation && (
                        <small style={{ color: 'grey', fontSize: '12px' }}>powered by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                    )}
                </p>
            </div>
        </div>
    );
};

export default Footer;
