import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import Footer from "./components/Footer";
import Reducer from "../utilities/Reducer";
import "../css/style.css";

const Step20_Checkout = () => {
    const [show, setShow] = useState(0);
    const [resources, setResources] = useState({});
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [token] = useState(sessionStorage.getItem("token"));
    const [order, setOrder] = useState([]);
    const [orderInfo, setOrderInfo] = useState({});
    const [bookedTicketTypeInfo, setBookedTicketTypeInfo] = useState([]);
    const [requested, setRequested] = useState(0);
    const [insurance, setInsurance] = useState(true);
    const [insuranceTotalAmount, setInsuranceTotalAmount] = useState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [promotionCode, setPromotionCode] = useState("");
    const [promotionError, setPromotionError] = useState({
        text: "",
        valid: false
    });
    const [addedPromotionCodes, setAddedPromotionCodes] = useState([]);
    const [hasAgreement, setHastAgreement] = useState(false);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const { language } = useParams();

    const [languageInfo, dispatch] = useReducer(Reducer, {
        id: 0,
        language: language,
    });

    const [payment, setPayment] = useState({
        currency: "CHF",
        paymentType: 0,
        deliveryType: 0,
    });

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    useEffect(() => {
        if (requested === 0) {
            requestCompletedOrder();
        }

        setOrderInsuranceTotalAmount();

        if (orderInfo && orderInfo.totalInsuranceAmount > 0) {
            setInsurance(true);
        }

        if (orderInfo && orderInfo.total == 0) {
            changePaymentType(5);
            setInsurance(false);
            finishOrder();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        requestResources();
    }, [language]);

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestCompletedOrder = () => {
        axios
            .get(`${baseUrl}/ShopBasket/Order/${orderId}`)
            .then((res) => {
                setOrder(res.data.tickets);
                setOrderInfo(res.data);
                setTotalPrice(res.data.totalPrice);

                setPayment({
                    ...payment,
                    deliveryType: res.data.deliveryMethodId,
                    paymentType: res.data.paymentMethodId,
                });
                res.data.tickets.map((t) => requestBookedTicketTypeInfo(t.ticketTypeId));

                // prevent overwriting
                setRequested(requested + 1);
            })
            .catch((error) => {
                console.error(error.response.data);
                navigate(`/${language}/sessiontimeout`);
            });
    };

    const setTotalAmountWithInsurance = (condition) => {
        setInsurance(!condition);

        if (!condition) {
            setTotalPrice(totalPrice + insuranceTotalAmount);
        } else {
            setTotalPrice(totalPrice - insuranceTotalAmount);
        }
    };

    const requestBookedTicketTypeInfo = (ticketTypeId) => {
        axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`).then((res) => {
            setBookedTicketTypeInfo((current) => [
                ...current,
                res.data.ticketTypeInfos.find(
                    (tti) => tti.languageId === languageInfo.id
                ),
            ]);
            setShow(true);
        });
    };

    const changeDeliveryType = (deliveryType) => {
        axios
            .put(
                `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${deliveryType}`
            )
            .then((res) => {
                setPayment({ ...payment, deliveryType: deliveryType });
                setOrderInfo(res.data);
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const changePaymentType = (paymentType) => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentType}`)
            .then((res) => {
                setPayment({ ...payment, paymentType: paymentType });
                setOrderInfo(res.data);
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const onAgreementChange = (e) => {
        setHastAgreement(e.target.checked);
    }

    const changePaymentTypeToFree = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(() => {
                navigate(`/${language}/confirmation`);
            });
    };

    const setOrderInsurance = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/Insurance`)
            .then((res) => {
                setInsurance(true);
                setOrderInfo(res.data);

                let total = orderInfo.totalPrice;

                if (orderInfo.totalInsuranceAmount === 0 && insurance) {
                    total = total + insuranceTotalAmount;
                }

                total = Math.round(total * 100) / 100;

                let price = total.toFixed(2) + " CHF";

                sessionStorage.setItem("totalPrice", price);

                let dataTransProviders = [1, 7, 8, 9, 10, 14, 11];

                if (
                    dataTransProviders.findIndex((d) => d === payment.paymentType) !== -1
                ) {
                    startDatatrans();
                } else {
                    navigate(`/${language}/confirmation`);
                }
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const setOrderInsuranceTotalAmount = () => {
        let amount = 0.0;

        order.map((ticket) => {
            if (ticket.price < 50) {
                amount = amount + 0.7;
            } else if (ticket.price > 50 && ticket.price < 75) {
                amount = amount + 0.95;
            }
        });

        setInsuranceTotalAmount(Math.round(100 * amount) / 100);
    };

    // calling the popup
    const startDatatrans = () => {
        var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

        axios
            .get(baseRequestUrl)
            .then((res) => {
                let datatransFormId = "datatrans-" + new Date().getTime();
                var form =
                    "<form class='datatrans' id='" + datatransFormId + "'></form>";

                let container = document.getElementById("datatrans-form-placeholder");

                container.innerHTML += form;

                let element = document.getElementById(datatransFormId);

                // merchantId for testing
                // element.setAttribute("data-merchant-id", "1100004624");
                element.setAttribute("data-merchant-id", res.data.merchantId);
                element.setAttribute("data-amount", res.data.amount);
                element.setAttribute("data-currency", res.data.currency);
                element.setAttribute("data-refno", res.data.referenceNumber);
                element.setAttribute("data-reqType", res.data.reqType);
                element.setAttribute(
                    "data-upp-return-target",
                    res.data.uppReturnTarget
                );
                element.setAttribute("data-paymentMethod", res.data.paymentMethod);
                element.setAttribute("data-sign", res.data.digitalSignature);

                let domain = "https://" + window.location.host;

                let successUrl = domain + `/form/redirect/${language}/success/${orderId}`;
                let errorUrl = domain + `/form/redirect/${language}/error/${orderId}`;
                let cancelUrl = domain + `/form/redirect/${language}/cancel/${orderId}`;

                element.setAttribute("data-success-url", successUrl);
                element.setAttribute("data-error-url", errorUrl);
                element.setAttribute("data-cancel-url", cancelUrl);

                for (const key in res.data.userInfo) {
                    element.setAttribute(key, res.data.userInfo[key]);
                }

                for (const key in res.data.merchantSpecificParameters) {
                    element.setAttribute(key, res.data.merchantSpecificParameters[key]);
                }

                // start datatrans -> call the payment form
                window.Datatrans.startPayment({
                    form: "#" + datatransFormId,
                });
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    // // confirm the order
    // const confirmOrder = () => {
    //   axios
    //     .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
    //     .then(() => {
    //       navigate(`/${language}/confirmation`);
    //     })
    //     .catch((error) => {
    //       alert(error.response.data);
    //     });
    // };

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
        } catch (error) {
            console.error(error)
        }
    };

    const finishOrder = async () => {
        //if (insurance) {
        //    setOrderInsurance();
        //} else {
        let total = orderInfo.totalPrice;

        if (total == 0) {
            changePaymentTypeToFree();
        }

        if (orderInfo.totalInsuranceAmount === 0 && insurance) {
            total = total + insuranceTotalAmount;
        }

        total = Math.round(total * 100) / 100;

        let price = total.toFixed(2) + " CHF";

        sessionStorage.setItem("totalPrice", price);
        let dataTransProviders = [1, 7, 8, 9, 10, 14, 11];

        if (
            dataTransProviders.findIndex((d) => d === payment.paymentType) !== -1
        ) {
            startDatatrans();
        } else {
            await confirmShopbasketByOrderId(orderId);
            navigate(`/${language}/confirmation/${orderId}`);
        }
        //}
    };

    const mapSelectedTickets = () => {
        return (
            <div>
                {order.map((ticket, index) => (
                    <div className="row" key={index}>
                        <div className="col-md-10 fs-6">
                            {bookedTicketTypeInfo.find(
                                (btti) => btti.ticketTypeId === ticket.ticketTypeId
                            ) &&
                                bookedTicketTypeInfo.find(
                                    (btti) => btti.ticketTypeId === ticket.ticketTypeId
                                ).name}
                        </div>
                        <div className="col-md-2 text-end">
                            {ticket.price + " " + ticket.currency}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const mapAddedPromotionCodes = () => {
        return (
            <div>
                {addedPromotionCodes.map((code, index) => (
                    <div className="row" key={index}>
                        <div className="col-md-12 fs-6 text-success">
                            {code} (Promotion code)
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const mapExtraCosts = () => {
        return (
            <div>
                <p
                    className={
                        "d-flex justify-content-between align-items-start mb-2 fs-6"
                    }
                >
                    {resources.checkOut.TotalCosts}
                    <span></span>
                    {orderInfo.deliverPaymentFee.toFixed(2) + " " + orderInfo.currency}
                </p>
            </div>
        );
    };

    const mapInsuranceOfTickets = () => {
        let length = bookedTicketTypeInfo.length;

        return (
            <div>
                <p
                    className={
                        "d-flex justify-content-between align-items-start mb-2 fs-6"
                    }
                >
                    Ticketversicherung
                    <span></span>
                    {insuranceTotalAmount.toFixed(2) + " " + orderInfo.currency}
                </p>
            </div>
        );
    };

    const totalSelectedTickets = () => {
        let total = orderInfo.totalPrice;

        //if (orderInfo.totalInsuranceAmount === 0 && insurance) {
        //    total = total + insuranceTotalAmount;
        //}

        total = Math.round(total * 100) / 100;

        return (
            <div>
                <p className={"d-flex justify-content-between align-items-start mb-2"}>
                    <p className="fw-bold fs-6">{resources.checkOut.TotalTax}</p>
                    <span></span>
                    <p className="fw-bold fs-6">
                        {orderInfo && total.toFixed(2) + " " + orderInfo.currency}
                    </p>
                </p>
            </div>
        );
    };

    const promotionCodeElement = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-8 col-12 pb-3">
                        <input
                            id="inputCode"
                            type="text"
                            name="promotionCode"
                            className="form-control border-dark mt-1"
                            value={promotionCode}
                            onChange={(e) => setPromotionCode(e.target.value)}
                        />
                    </div>
                    <div className="col-md-4 col-3 text-center">
                        <button
                            className="btn form-control btn-custom"
                            onClick={() => applyPromotionCode()}
                            disabled={promotionCode === ""}
                        >
                            {resources.translation._Add}
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {!promotionError.valid ? <p className="text-danger fs-6">
                            {promotionError.text}
                        </p> :
                            <p className="text-success fs-6">
                                {promotionError.text}
                            </p>}
                    </div>
                </div>
            </>
        );
    };

    const applyPromotionCode = () => {
        axios
            .put(
                `${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotionCode}`
            )
            .then((res) => {
                setPromotionError({
                    text: `${resources.checkOut.PromocodeSuccess} (${promotionCode})`,
                    valid: true
                });

                setAddedPromotionCodes((current) => [...current, promotionCode]);

                requestCompletedOrder();
            })
            .catch((error) => {
                setPromotionError({
                    text: resources.checkOut.PromocodeError,
                    valid: false
                });
            });
    };

    console.log(hasAgreement);

    return (
        <div>
            <div id="datatrans-form-placeholder"></div>

            {resources.translation && (
                <div className="container pt-0 wrapper">
                    <div className="row mt-5 title-box">
                        <h4>{resources.checkOut.Promocode}</h4>
                    </div>
                    {/*promocode*/}

                    <div className="row mt-5">
                        <div>{promotionCodeElement()}</div>
                    </div>

                    {/*delivery types*/}
                    <div className="mt-5">
                        <div className="row title-box">
                            <h4>{resources.checkOut.Delivery}</h4>
                        </div>
                        <div className="mb-5 mt-5 check-page-box">
                            <div className="mb-3">
                                <input
                                    className="me-3"
                                    type="radio"
                                    name="flexRadioDefault1"
                                    checked={payment.deliveryType === 1 ? true : false}
                                    onClick={() => changeDeliveryType(1)}
                                    id="printAtHome"
                                />
                                <label
                                    className="form-check-label fs-6 bold"
                                    htmlFor="printAtHome"
                                >
                                    {resources.checkOut.DeliveryPrintAtHome}
                                </label>
                                <p className="pt-1 m-0">
                                    <small className="margin-fees">{resources.checkOut.DeliveryNoFee}</small>
                                </p>
                            </div>
                            <div className="mb-3 mt-4">
                                <input
                                    className="me-3"
                                    type="radio"
                                    name="flexRadioDefault1"
                                    checked={payment.deliveryType === 2 ? true : false}
                                    onClick={() => changeDeliveryType(2)}
                                    id="postMail"
                                />
                                <label
                                    className="form-check-label fs-6 bold"
                                    htmlFor="postMail"
                                >
                                    {resources.checkOut.DeliveryPost}
                                </label>
                                <p className="pt-1 m-0">
                                    <small className="margin-fees">{resources.checkOut.DeliveryFee}</small>
                                </p>
                            </div>
                        </div>

                        {/*payment types*/}
                        {totalPrice > 0 && <>
                            <div className="row title-box">
                                <h4>{resources.checkOut.Payment}</h4>
                            </div>
                            <div className="mb-4 mt-5 check-page-box">
                                <div className="mb-3">
                                    <input
                                        className="me-3"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={payment.paymentType === 2 ? true : false}
                                        onClick={() => changePaymentType(2)}
                                        id="invoice"
                                    />
                                    <label
                                        className="form-check-label fs-6 bold"
                                        htmlFor="invoice"
                                    >
                                        {resources.checkOut.PaymmentInvoice}
                                    </label>
                                    <p className="pt-1 m-0">
                                        <small className="margin-fees">{resources.checkOut.PaymentSmallFee}</small>
                                    </p>
                                </div>
                                {/*<div className="mb-3 mt-4">*/}
                                {/*    <input*/}
                                {/*        className="me-3"*/}
                                {/*        type="radio"*/}
                                {/*        name="flexRadioDefault"*/}
                                {/*        checked={payment.paymentType === 11 ? true : false}*/}
                                {/*        onClick={() => changePaymentType(11)}*/}
                                {/*        id="sofort"*/}
                                {/*    />*/}
                                {/*    <label*/}
                                {/*        className="form-check-label fs-6 bold"*/}
                                {/*        htmlFor="sofort"*/}
                                {/*    >*/}
                                {/*        {resources.checkOut.PaymentSofort}*/}
                                {/*    </label>*/}
                                {/*    <p className="pt-1 m-0">*/}
                                {/*        <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                                {/*<div className="mb-3 mt-4">*/}
                                {/*    <input*/}
                                {/*        className="me-3"*/}
                                {/*        type="radio"*/}
                                {/*        name="flexRadioDefault"*/}
                                {/*        checked={payment.paymentType === 10 ? true : false}*/}
                                {/*        onClick={() => changePaymentType(10)}*/}
                                {/*        id="paypal"*/}
                                {/*    />*/}
                                {/*    <label*/}
                                {/*        className="form-check-label fs-6 bold"*/}
                                {/*        htmlFor="paypal"*/}
                                {/*    >*/}
                                {/*        PayPal*/}
                                {/*    </label>*/}
                                {/*    <p className="pt-1 m-0">*/}
                                {/*        Zusatzgebühr von CHF + 4.9%; EUR + 3.9%.*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                                <div className="mb-3 mt-4">
                                    <input
                                        className="me-3"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={payment.paymentType === 1 ? true : false}
                                        onClick={() => changePaymentType(1)}
                                        id="mastercard"
                                    />
                                    <label
                                        className="form-check-label fs-6 bold"
                                        htmlFor="mastercard"
                                    >
                                        Mastercard
                                    </label>
                                    <p className="pt-1 m-0">
                                        <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>
                                    </p>
                                </div>
                                <div className="mb-3 mt-4">
                                    <input
                                        className="me-3"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={payment.paymentType === 7 ? true : false}
                                        onClick={() => changePaymentType(7)}
                                        id="visa"
                                    />
                                    <label
                                        className="form-check-label fs-6 bold"
                                        htmlFor="visa"
                                    >
                                        Visa
                                    </label>
                                    <p className="pt-1 m-0">
                                        <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>
                                    </p>
                                </div>
                                <div className="mb-3 mt-4">
                                    <input
                                        className="me-3"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={payment.paymentType === 8 ? true : false}
                                        onClick={() => changePaymentType(8)}
                                        id="amex"
                                    />
                                    <label
                                        className="form-check-label fs-6 bold"
                                        htmlFor="amex"
                                    >
                                        American Express
                                    </label>
                                    <p className="pt-1 m-0">
                                        <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>
                                    </p>
                                </div>
                                {/*<div className="mb-3 mt-4">*/}
                                {/*    <input*/}
                                {/*        className="me-3"*/}
                                {/*        type="radio"*/}
                                {/*        name="flexRadioDefault"*/}
                                {/*        checked={payment.paymentType === 9 ? true : false}*/}
                                {/*        onClick={() => changePaymentType(9)}*/}
                                {/*        id="postfinance"*/}
                                {/*    />*/}
                                {/*    <label*/}
                                {/*        className="form-check-label fs-6 bold"*/}
                                {/*        htmlFor="postfinance"*/}
                                {/*    >*/}
                                {/*        PostFinance*/}
                                {/*    </label>*/}
                                {/*    <p className="pt-1 m-0">*/}
                                {/*        {resources.checkOut.PaymentFee1}*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                                <div className="mb-3 mt-4">
                                    <input
                                        className="me-3"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={payment.paymentType === 14 ? true : false}
                                        onClick={() => changePaymentType(14)}
                                        id="twint"
                                    />
                                    <label
                                        className="form-check-label fs-6 bold"
                                        htmlFor="twint"
                                    >
                                        Twint
                                    </label>
                                    <p className="pt-1 m-0">
                                        <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>
                                    </p>
                                </div>
                            </div>
                            {/*{<div className="row">
                                <div className="col-md-12 mb-2">
                                    <h1 className="fs-5 red">Ticketversicherung</h1>
                                </div>
                            </div>
                            <div class="mb-5">
                                <input
                                    className="me-4"
                                    type="checkbox"
                                    onClick={() => setTotalAmountWithInsurance(insurance)}
                                    checked={insurance}
                                />
                                <label className="form-check-label fs-6" for="inlineCheckbox1">
                                    Mit dem Abschluss der Ticketversicherung{" "}
                                    <span className="fw-bold">
                                        ({insuranceTotalAmount.toFixed(2) + " CHF"})
                                    </span>{" "}
                                    erhalten Sie im Verhinderungsfall den Ticketpreis zurück
                                    erstattet.
                                </label>
                            </div>*/}
                        </>}

                        {/*summary*/}
                        <div className="row mb-5 mt-5 title-box">
                            <h4>{resources.checkOut.Summary}</h4>
                        </div>
                        <div>
                            {mapSelectedTickets()}
                            {/*{insurance && mapInsuranceOfTickets()}*/}
                            {orderInfo.deliverPaymentFee > 0 && mapExtraCosts()}
                            {mapAddedPromotionCodes()}
                            <hr></hr>
                            {totalSelectedTickets()}
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12 float-end">
                            <input id="chkAgreement" type="checkbox" className="me-2" value="agreement" onChange={(e) => onAgreementChange(e)} />
                            <label htmlFor="chkAgreement" dangerouslySetInnerHTML={{ __html: resources.checkOut.Agreement }}></label>
                        </div>
                    </div>

                    {/* next button */}
                    <div className={"row mt-5"}>
                        <div className="col-md-4 text-end">
                            <button
                                className="btn form-control btn-custom"
                                onClick={() => navigate(`/${language}/shipping`)}
                            >
                                {resources.translation._Back}
                            </button>
                        </div>
                        <div className="offset-md-4 col-md-4 text-end">
                            <button
                                className="btn form-control btn-custom"
                                onClick={() => finishOrder()}
                                disabled={
                                    payment.paymentType === 0 && payment.deliveryType === 0 || !hasAgreement
                                }
                            >
                                {resources.checkOut.FinishOrder}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* footer */}
            <div className="mt-5 footerBgColor">
                <div className="container wrapper">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <Footer language={language} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step20_Checkout;
